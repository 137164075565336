import React from "react";

const experienceContent = [
  {
    year: "2020 - Present",
    position: "Full Stack Developer | Data Scientist",
    companyName: "korrect Technologies, Abuja",
    details: "Drive continuous improvement by maintaining working knowledge of the latest changes in software development, data, and automation"
  },
  {
    year: "2018 - 2020",
    position: "System Administrator",
    companyName: "Soul Lounge and Suites, Kubwa, Abuja",
    details: "Demonstrated strong analytical and problem-solving skills, addressing complex technical issues efficiently"
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
