import React from "react";

const skillsContent = [
  { skillClass: "p85", skillPercent: "95", skillName: "PYTHON" },
  { skillClass: "p89", skillPercent: "89", skillName: "POWER BI" },
  { skillClass: "p70", skillPercent: "70", skillName: "EXCEL" },
  { skillClass: "p75", skillPercent: "75", skillName: "SQL" },
  { skillClass: "p95", skillPercent: "95", skillName: "WORDPRESS" },
  { skillClass: "p77", skillPercent: "77", skillName: "PHP" },
  // { skillClass: "p50", skillPercent: "50", skillName: "JQUERY" },
  // { skillClass: "p65", skillPercent: "65", skillName: "ANGULAR" },
  { skillClass: "p71", skillPercent: "71", skillName: "REACT" },
  { skillClass: "p55", skillPercent: "55", skillName: "NODEJS" },
  { skillClass: "p73", skillPercent: "73", skillName: "NODEJS" },
  //add more data science skills
  { skillClass: "p85", skillPercent: "85", skillName: "MACHINE LEARNING" },
  { skillClass: "p80", skillPercent: "80", skillName: "DEEP LEARNING" },
  { skillClass: "p75", skillPercent: "75", skillName: "DATA ANALYSIS" },
  { skillClass: "p70", skillPercent: "70", skillName: "DATA VISUALIZATION" },
  { skillClass: "p65", skillPercent: "65", skillName: "NLP" },
  { skillClass: "p81", skillPercent: "81", skillName: "GENERATIVE AI"},
  { skillClass: "p97", skillPercent: "97", skillName: "COMPUTER VISION" },
];

const Skills = () => {
  return (
    <>
      {skillsContent.map((val, i) => (
        <div className="col-6 col-md-3 mb-3 mb-sm-5" key={i}>
          <div className={`c100 ${val.skillClass}`}>
            <span>{val.skillPercent}%</span>
            <div className="slice">
              <div className="bar"></div>
              <div className="fill"></div>
            </div>
          </div>
          <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">
            {val.skillName}
          </h6>
        </div>
      ))}
    </>
  );
};

export default Skills;
